<template>
  <div class="our-community-container">
    <b-row class="w-100 m-0">
      <b-col
        v-for="(item, index) in listContact.filter(item => item.hide !== true)"
        :key="index"
        sm="12"
        md="6"
        class="list-contact"
      >
        <div class="item-contact">
          <div class="d-flex flex-column align-items-center">
            <b-img
              :src="item.iconContact"
              alt="icon contact"
              class="icon-contact"
            />
            <h3 class="title-contact">
              {{ item.titleContact }}
            </h3>
            <p class="content-contact">
              {{ item.subContact }}
            </p>
          </div>

          <div class="btn-controller">
            <btn-loading
              id="trigger-join-now"
              variant-convert="btn-submit"
              class="btn-join-now"
              pill
              @click.prevent="handelGoToContact(item.linkContact)"
            >
              {{ $t('referral.tabAffiliateTerms.btnJoinNow') }}
            </btn-loading>
          </div>
        </div>
      </b-col>
    </b-row>

    <div class="note-container">
      <h3 class="mb-1">
        {{ $t('referral.tabOurCommunity.textSupport') }}
      </h3>
      <div class="d-flex mb-1">
        <feather-icon
          icon="MailIcon"
          size="24"
          class="mr-1"
        />
        <p class="m-0">
          Email: <span class="text-bold underline">{{ emailSupportPartnero }}</span>
        </p>
      </div>
      <div class="d-flex">
        <feather-icon
          icon="PhoneIcon"
          size="24"
          class="mr-1"
        />
        <p class="m-0">
          Whatsapp: <span class="text-bold">{{ whatsAppSupportPartnero }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BImg,
} from 'bootstrap-vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import envMixin from '@/mixins/envMixin'

export default {
  components: {
    BtnLoading,
    BRow,
    BCol,
    BImg,
  },

  mixins: [envMixin],

  computed: {
    listContact() {
      return [
        {
          // eslint-disable-next-line global-require
          iconContact: require('@/assets/images/pages/partnero/ic-lark.png'),
          titleContact: this.$t('referral.tabOurCommunity.titleLarkCommunity'),
          subContact: this.$t('referral.tabOurCommunity.subLarkCommunity', { platformName: this.platformName }),
          linkContact: this.larkSupportPartneroUrl,
          hide: !this.larkSupportPartneroUrl,
        },
        {
          // eslint-disable-next-line global-require
          iconContact: require('@/assets/images/pages/partnero/ic-facebook.png'),
          titleContact: this.$t('referral.tabOurCommunity.titleFacebookCommunity'),
          subContact: this.$t('referral.tabOurCommunity.subFacebookCommunity'),
          linkContact: this.facebookSupportPartneroUrl,
          hide: !this.facebookSupportPartneroUrl,
        },
      ]
    },
  },

  methods: {
    handelGoToContact(url) {
      window.open(url)
    },
  },
}
</script>

<style scoped lang="scss">
.list-contact {
  padding: 0;

  .item-contact {
    padding: 20px;
    align-content: center;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .icon-contact, .title-contact, .content-contact {
      margin-bottom: 10px;
      text-align: center;
    }
  }

  &:first-child {
    padding-right: 12px;
  }

  &:last-child {
    padding-left: 12px;
  }

  @media (max-width: 767px) {
    &:first-child {
      padding-right: 0;
      margin-bottom: 1rem;
    }

    &:last-child {
      padding-left: 0;
    }
  }
}

.note-container {
  margin-top: 30px;
  padding: 20px;
  border-radius: 20px;
  background: rgba(38, 103, 255, 0.10);
  display: flex;
  flex-direction: column;
}

.text-bold{
  font-family: "ProximaNovaA-medium", Helvetica, Arial, serif;
}
</style>

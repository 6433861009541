<template>
  <div class="affiliate-teams-container">
    <h1 class="title-tab">
      {{ $t('referral.tabAffiliateTerms.contentTitleTab') }}
    </h1>

    <b-row class="list-card">
      <b-col
        v-for="(card, index) in listCard"
        :key="index"
        sm="6"
        md="4"
        class="card-item"
      >
        <div v-if="card.title === $t('referral.tabAffiliateTerms.titleRevenueShare')">
          <div class="d-flex align-items-center">
            <b-img
              :src="card.icon"
              alt="icon revenue share"
            />
            <h4 class="title-content">
              {{ card.title }}
            </h4>
          </div>
          <div class="content-card">
            <p>
              {{ $t('referral.tabAffiliateTerms.txtEarnCompetitiveCommissions') }}: <span
                class="text-bold"
              >{{ $t('referral.tabAffiliateTerms.txtMonthlySubscription') }}</span>, {{
                $t('referral.tabAffiliateTerms.txtAnd')
              }} <span class="text-bold">{{ $t('referral.tabAffiliateTerms.txtTheTotalAdSpending') }}</span>
              ({{ $t('referral.tabAffiliateTerms.txtFirstThreeMonths') }}).
            </p>
          </div>
        </div>
        <div v-else>
          <div
            class="d-flex align-items-center"
          >
            <b-img
              :src="card.icon"
              alt="icon revenue share"
            />
            <h4 class="title-content">
              {{ card.title }}
            </h4>
          </div>
          <div class="content-card">
            <p>
              {{ card.content }}
            </p>
          </div>
        </div>
      </b-col>
    </b-row>

    <div class="btn-controller">
      <btn-loading
        v-if="infoPartneroUser && infoPartneroUser.partner"
        class="btn-join-now"
        variant-convert="btn-submit"
        @click.prevent="goToUrl(urlLoginPartneroPage)"
      >
        {{ $t('resetPW.btnLogin') }}
      </btn-loading>
      <btn-loading
        v-else
        id="trigger-join-now"
        variant-convert="btn-submit"
        class="btn-join-now"
        pill
        :loading="loading"
        @click="handleJoinPartneroPrograms"
      >
        {{ $t('referral.tabAffiliateTerms.btnJoinNow') }}
      </btn-loading>
    </div>

    <b-card-text>
      <h1 class="title-tab">
        {{ $t('referral.tabAffiliateTerms.contentTitleLetYourClients') }}
      </h1>

      <div class="step-wrapper">
        <div class="step-block">
          <div class="step-icon">
            1
          </div>
          <div class="step-body">
            <h4>{{ $t('referral.tabAffiliateTerms.titleSignUp') }}</h4>
            <p>{{ $t('referral.tabAffiliateTerms.txtRegisterForAnAccount') }} <span
              class="text-blue cursor-pointer text-bold"
              @click.prevent="handleJoinPartneroPrograms"
            >{{ $t('addFundPage.textHere') }}</span>. {{ $t('referral.tabAffiliateTerms.txtCheckYourEmailForLogin') }}
              <span
                class="text-blue cursor-pointer text-bold"
                @click.prevent="handleGoToLoginPagePartnero"
              >{{ $t('referral.tabAffiliateTerms.txtEcomdyAffiliatePlatform', {platformName: platformName}) }}</span>.
              {{ $t('referral.tabAffiliateTerms.txtItFreeAndTakes') }}</p>
          </div>
        </div>
        <!-- end step 1 -->

        <div class="step-block">
          <div class="step-icon">
            2
          </div>
          <div class="step-body">
            <h4>{{ $t('referral.tabAffiliateTerms.titleShare') }}</h4>
            <p>{{ $t('referral.tabAffiliateTerms.txtLogin') }} <span
              class="text-blue cursor-pointer text-bold"
              @click.prevent="handleGoToLoginPagePartnero"
            >{{ $t('addFundPage.textHere') }}</span> {{ $t('referral.tabAffiliateTerms.txtToCopyYourAffiliateLink') }}
            </p>
          </div>
        </div>
        <!-- end step 2 -->

        <div class="step-block">
          <div class="step-icon">
            3
          </div>
          <div class="step-body">
            <h4>
              {{ $t('referral.tabAffiliateTerms.titleEarn') }}
            </h4>
            <p>
              {{ $t('referral.tabAffiliateTerms.subEarn') }}
            </p>
          </div>
        </div>
        <!-- end step 3 -->

        <div class="step-block">
          <div class="step-icon">
            4
          </div>
          <div class="step-body">
            <h4>
              {{ $t('referral.tabAffiliateTerms.titleJoinCommunity') }}
            </h4>
            <p>
              {{ $t('referral.tabAffiliateTerms.txtJoinOut') }} <a
                v-if="larkSupportPartneroUrl"
                :href="larkSupportPartneroUrl"
                target="_blank"
                class="text-bold"
              >{{ $t('referral.tabAffiliateTerms.txtLarkGroup') }}</a>
              <span v-if="larkSupportPartneroUrl && facebookSupportPartneroUrl"> {{ $t('referral.tabAffiliateTerms.txtAnd') }} </span>
              <a
                v-if="facebookSupportPartneroUrl"
                :href="facebookSupportPartneroUrl"
                target="_blank"
                class="text-bold"
              >{{ $t('referral.tabAffiliateTerms.txtFacebookGroup') }}</a>
              {{ $t('referral.tabAffiliateTerms.txtForUpdates') }}
            </p>
          </div>
        </div>
        <!-- end step 4 -->
      </div>
    </b-card-text>

    <div class="note-card">
      <div class="ic-controller">
        <feather-icon
          icon="InfoIcon"
          size="24"
          class="icon-note"
        />
      </div>
      <p class="content-note">
        {{ $t('referral.tabAffiliateTerms.textAnyDisputes') }}: <span
          class="text-bold"
        >{{ emailSupportPartnero }}</span>.
        {{ $t('referral.tabAffiliateTerms.textAllOfEcomdyMediaDecision', { companyName: companyName }) }}
        <span
          class="text-blue underline font-weight-600 cursor-pointer"
          @click="goToUrl(urlTeamAndConditions)"
        >{{ $t('referral.tabAffiliateTerms.textTermAndConditions') }}</span>
      </p>
    </div>

    <join-partnero-success-modal
      ref="open-join-partnero-success-modal"
      :mail-joined="mailJoined"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BImg, BCardText,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import envMixin from '@/mixins/envMixin'
import _isEmpty from 'lodash/isEmpty'
import JoinPartneroSuccessModal from './joinPartneroSuccessModal.vue'

const {
  mapGetters,
  mapActions,
} = createNamespacedHelpers('referralProgramsNew')
const {
  mapGetters: mapGettersAuth,
  mapActions: mapActionsAuth,
} = createNamespacedHelpers('auth')

export default {
  components: {
    JoinPartneroSuccessModal,
    BRow,
    BCol,
    BImg,
    BCardText,
    BtnLoading,
  },

  mixins: [toastification, envMixin],

  data() {
    return {
      mailJoined: null,
      infoPartneroUser: null,
      urlLoginPartneroPage: process.env.VUE_APP_PARTNERO_LOGIN_URL,
      urlTeamAndConditions: process.env.VUE_APP_PARTNERO_TERM_CONDITION,
      urlAffiliatePartnero: process.env.VUE_APP_AFFILIATE_PARTNERO_URL,
    }
  },

  computed: {
    ...mapGetters(['status', 'loading', 'message', 'accountPartnero']),
    ...mapGettersAuth(['user']),

    listCard() {
      return [
        {
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/pages/partnero/ic-revenue-share.png'),
          title: this.$t('referral.tabAffiliateTerms.titleRevenueShare'),
          content: this.$t('referral.tabAffiliateTerms.contentRevenueShare'),
        },
        {
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/pages/partnero/ic-monthly-payout.png'),
          title: this.$t('referral.tabAffiliateTerms.titleMonthlyPayout'),
          content: this.$t('referral.tabAffiliateTerms.contentMonthlyPayout'),
        },
        {
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/pages/partnero/ic-bonus-program.png'),
          title: this.$t('referral.tabAffiliateTerms.titleBonusProgram'),
          content: this.$t('referral.tabAffiliateTerms.contentBonusProgram'),
        },
        {
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/pages/partnero/ic-secure-affiliate-portal.png'),
          title: this.$t('referral.tabAffiliateTerms.titleSecureAffiliatePortal'),
          content: this.$t('referral.tabAffiliateTerms.contentSecureAffiliatePortal', { platformName: this.platformName }),
        },
        {
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/pages/partnero/ic-quality-community.png'),
          title: this.$t('referral.tabAffiliateTerms.titleQualityCommunity'),
          content: this.$t('referral.tabAffiliateTerms.contentQualityCommunity'),
        },
        {
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/pages/partnero/ic-marketing-sales-support.png'),
          title: this.$t('referral.tabAffiliateTerms.titleMarketingAndSalesSupport'),
          content: this.$t('referral.tabAffiliateTerms.contentMarketingAndSalesSupport', { platformName: this.platformName }),
        },
      ]
    },
  },

  watch: {
    user: {
      handler(userInfo) {
        if (!_isEmpty(userInfo?.data?.partnero)) {
          this.infoPartneroUser = userInfo?.data?.partnero
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['joinPartneroPrograms']),
    ...mapActionsAuth(['getAccountInfo']),

    goToUrl(url) {
      window.open(url)
    },

    async handleJoinPartneroPrograms() {
      await this.joinPartneroPrograms()
      if (this.status) {
        this.mailJoined = this.accountPartnero?.poEmail
        await this.getAccountInfo()
        this.$refs['open-join-partnero-success-modal'].showModal()
      } else {
        this.toastFailure(this.message)
      }
    },

    handleGoToLoginPagePartnero() {
      window.open(this.urlAffiliatePartnero)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-referral';
</style>

<style scoped lang="scss">
.text-bold {
  font-family: "ProximaNovaA-Medium", Helvetica, Arial, serif;
  font-weight: 600;
}

.affiliate-teams-container {
  padding: 0 20px 20px;

  .title-tab {
    margin-bottom: 30px;
    font-size: 32px;
  }

  .list-card {
    width: 100%;
    padding: 0 1rem;

    .card-item {
      padding: 1rem;

      .title-content {
        margin: 0 0 0 12px;
      }

      .content-card {
        margin-top: .5rem;
      }
    }

    @media (max-width: 576px) {
      padding: 0;
    }
  }

  .btn-controller {
    margin: 30px 0;
    display: flex;
    justify-content: center;

    .btn-join-now {
      padding: 12px 16px;
      width: 190px;
      height: 40px;
    }
  }

  .step-wrapper {
    position: relative;
    padding-left: 40px;
    margin: 0;

    &:before {
      content: "";
      position: absolute;
      left: 10px;
      top: 0;
      width: 1px;
      height: 100%;
      border-left: 1px dashed #e0e0e5;
      display: block;
    }

    .step-block {
      margin: 30px;
      position: relative;
      margin-bottom: 2.5rem;

      .step-icon {
        position: absolute;
        left: -78px;
        top: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        vertical-align: middle;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        background: #6e44ff;
        padding-top: 7px;

        @media (max-width: 767px) {
          left: -59px;
        }
      }

      .step-body {
        h4 {
          font-weight: bold;
        }

        a {
          color: #6e44ff;
        }
      }

      .copy-btn {
        height: 50px;
        border-radius: 8px;
      }

      .referral-code {
        background: #fff;
        height: 50px;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      @media (max-width: 767px) {
        margin: 10px;

        .referral-code {
          width: 100%;
        }

        .copy-btn {
          margin: 10px 0 30px 0;
        }
      }
    }
  }

  .note-card {
    padding: 12px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    background: rgba(38, 103, 255, 0.10);
    margin-top: 30px;

    .ic-controller {
      margin-right: 12px;
    }

    .content-note {
      margin: 0;
    }
  }

  @media (max-width: 767px) {
    padding: 0;
  }
}
</style>
